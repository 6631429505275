import axios from 'axios';
import { sprig } from '@sprig-technologies/sprig-browser';
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const devEnvironmentId = import.meta.env.VITE_SPRIG_DEV_ENVIRONMENT_ID;
const prodEnvironmentId = import.meta.env.VITE_SPRIG_PROD_ENVIRONMENT_ID;
const environmentId = window.location.href.includes('adlt-ai.test') ? devEnvironmentId : prodEnvironmentId;

window.sprig = sprig.configure({
    environmentId,
})

// window.Pusher = Pusher;
 
// window.Echo = new Echo({
//     broadcaster: 'reverb',
//     key: import.meta.env.VITE_REVERB_APP_KEY,
//     wsHost: import.meta.env.VITE_REVERB_HOST,
//     wsPort: import.meta.env.VITE_REVERB_PORT,
//     wssPort: import.meta.env.VITE_REVERB_PORT,
//     forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });